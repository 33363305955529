var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('core-page-title'),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"8"}},[_c('v-card',{staticClass:"px-2 py-2"},[_c('v-card-text',{staticClass:"pt-0 text-center"},[_c('validation-observer',{ref:"observer"},[_c('v-row',[_c('v-col',[_c('validation-provider',{attrs:{"vid":"customerId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-autocomplete',{attrs:{"item-text":"nickname","item-value":"id","label":"Cliente","hide-details":"auto","items":_vm.customers,"loading":_vm.loadingCustomers,"error-messages":errors},model:{value:(_vm.filters.customerId),callback:function ($$v) {_vm.$set(_vm.filters, "customerId", $$v)},expression:"filters.customerId"}})]}}])})],1)],1),_c('v-row',[_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('validation-provider',{attrs:{"vid":"startDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Data Início","hide-details":"auto","append-icon":"mdi-calendar","error-messages":errors},on:{"blur":function($event){_vm.filters.startDate = _vm.dateFormatIso(
                              _vm.formatedStartDate
                            )},"click:append":function($event){_vm.menuStartDate = true}},model:{value:(_vm.formatedStartDate),callback:function ($$v) {_vm.formatedStartDate=$$v},expression:"formatedStartDate"}})]}}],null,true)})]}}]),model:{value:(_vm.menuStartDate),callback:function ($$v) {_vm.menuStartDate=$$v},expression:"menuStartDate"}},[_c('v-date-picker',{staticClass:"ma-0",attrs:{"no-title":""},on:{"input":function($event){_vm.menuStartDate = false}},model:{value:(_vm.filters.startDate),callback:function ($$v) {_vm.$set(_vm.filters, "startDate", $$v)},expression:"filters.startDate"}})],1)],1),_c('v-col',[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){return [_c('validation-provider',{attrs:{"vid":"endDate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                            var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":"Data Fim","hide-details":"auto","append-icon":"mdi-calendar","error-messages":errors},on:{"blur":function($event){_vm.filters.endDate = _vm.dateFormatIso(_vm.formatedEndDate)},"click:append":function($event){_vm.menuEndDate = true}},model:{value:(_vm.formatedEndDate),callback:function ($$v) {_vm.formatedEndDate=$$v},expression:"formatedEndDate"}})]}}],null,true)})]}}]),model:{value:(_vm.menuEndDate),callback:function ($$v) {_vm.menuEndDate=$$v},expression:"menuEndDate"}},[_c('v-date-picker',{staticClass:"ma-0",attrs:{"no-title":""},on:{"input":function($event){_vm.menuEndDate = false}},model:{value:(_vm.filters.endDate),callback:function ($$v) {_vm.$set(_vm.filters, "endDate", $$v)},expression:"filters.endDate"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"info","disabled":_vm.loadingCustomers || _vm.loading,"loading":_vm.loading},on:{"click":_vm.generate}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-text-box-search-outline')}}),_vm._v(" Gerar ")],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }