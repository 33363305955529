<template>
  <div>
    <core-page-title />
    <v-container fluid>
      <v-row justify="center">
        <v-col md="8">
          <v-card class="px-2 py-2">
            <v-card-text class="pt-0 text-center">
              <validation-observer ref="observer">
                <v-row>
                  <v-col>
                    <validation-provider v-slot="{ errors }" vid="customerId">
                      <v-autocomplete
                        v-model="filters.customerId"
                        item-text="nickname"
                        item-value="id"
                        label="Cliente"
                        hide-details="auto"
                        :items="customers"
                        :loading="loadingCustomers"
                        :error-messages="errors"
                      />
                    </validation-provider>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-menu
                      v-model="menuStartDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{}">
                        <validation-provider
                          v-slot="{ errors }"
                          vid="startDate"
                        >
                          <v-text-field
                            v-model="formatedStartDate"
                            v-mask="'##/##/####'"
                            label="Data Início"
                            hide-details="auto"
                            append-icon="mdi-calendar"
                            :error-messages="errors"
                            @blur="
                              filters.startDate = dateFormatIso(
                                formatedStartDate,
                              )
                            "
                            @click:append="menuStartDate = true"
                          />
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="filters.startDate"
                        no-title
                        class="ma-0"
                        @input="menuStartDate = false"
                      />
                    </v-menu>
                  </v-col>
                  <v-col>
                    <v-menu
                      v-model="menuEndDate"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{}">
                        <validation-provider v-slot="{ errors }" vid="endDate">
                          <v-text-field
                            v-model="formatedEndDate"
                            v-mask="'##/##/####'"
                            label="Data Fim"
                            hide-details="auto"
                            append-icon="mdi-calendar"
                            :error-messages="errors"
                            @blur="
                              filters.endDate = dateFormatIso(formatedEndDate)
                            "
                            @click:append="menuEndDate = true"
                          />
                        </validation-provider>
                      </template>
                      <v-date-picker
                        v-model="filters.endDate"
                        no-title
                        class="ma-0"
                        @input="menuEndDate = false"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
              </validation-observer>
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col>
                  <v-btn
                    color="info"
                    :disabled="loadingCustomers || loading"
                    :loading="loading"
                    @click="generate"
                  >
                    <v-icon v-text="'mdi-text-box-search-outline'" left />
                    Gerar
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import _ from 'lodash'
import customersApi from '@/api/customers'
import complementaryReportApi from '@/api/complementary-report'
import dateFormat from '@/utils/date-format'
import openBase64InNewTab from '@/utils/openBase64InNewTab'

export default {
  data() {
    return {
      loading: false,
      loadingCustomers: false,
      customers: [],
      filters: {
        startDate: null,
        endDate: null,
      },
      dateFormatIso: dateFormat.iso,
      menuStartDate: false,
      menuEndDate: false,
      formatedStartDate: null,
      formatedEndDate: null,
    }
  },

  watch: {
    'filters.startDate': {
      immediate: true,
      handler(newVal) {
        this.formatedStartDate = dateFormat.ptBr(newVal)
      },
    },
    'filters.endDate': {
      immediate: true,
      handler(newVal) {
        this.formatedEndDate = dateFormat.ptBr(newVal)
      },
    },
  },

  mounted() {
    this.loadCustomers()
  },

  methods: {
    async loadCustomers() {
      try {
        this.loadingCustomers = true

        const response = await customersApi.list()

        this.customers = response.data.customers
      } catch (e) {
        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loadingCustomers = false
      }
    },

    async generate() {
      try {
        this.loading = true
        this.$refs.observer.reset()

        const response = await complementaryReportApi.generate(_.pickBy(this.filters))

        const dataString = `data:application/pdf;base64,${response.data.pdf}`
        await openBase64InNewTab(dataString)
      } catch (e) {
        if (e?.response?.status === 422) {
          this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
          return
        }

        this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
